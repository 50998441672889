import { useEffect, useState, ReactNode } from 'react'
import ReactDOM from 'react-dom'

export interface IPortalProps {
  getContainer: () => HTMLElement
  children: ReactNode
}

type TElement = null | HTMLElement

export default function Portal({ getContainer, children }: IPortalProps) {
  const [element, updateElement] = useState<TElement>(null)
  useEffect(() => {
    updateElement(getContainer())
  }, [])
  return element && ReactDOM.createPortal(children, element)
}
