// @ts-nocheck
import React from 'react'
import Transition, {
  TransitionProps,
  TransitionStatus,
  ENTERED,
  ENTERING,
} from 'react-transition-group/Transition'
import classNames from 'classnames'

const fadeClassName = {
  [ENTERING]: 'show',
  [ENTERED]: 'show',
}

export const Fade = ({
  children,
  className,
  timeout = 300,
  appear = true,
  ...props
}: TransitionProps) => {
  return (
    <Transition timeout={timeout} appear={appear} {...props}>
      {(status: TransitionStatus, innerProps: any) =>
        // @ts-ignore
        React.cloneElement(children, {
          ...innerProps,
          className: classNames(
            'fade',
            className,
            // @ts-ignore
            children.props.className,
            // @ts-ignore
            fadeClassName[status]
          ),
        })
      }
    </Transition>
  )
}
